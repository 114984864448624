import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Box from "../components/box"
import Breadcrumb from "../components/breadcrumb"
import Button from "../components/button"
import Column from "../components/column"
import Columns from "../components/columns"
import Paragraph from "../components/paragraph"
import Divider from "../components/divider"
import Heading from "../components/heading"
import Image from "../components/image"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"

const TurmgespraecheIndex = () => {
  const data = useStaticQuery(graphql`
    query TurmgespraecheIndexQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "turmgespraeche" }
          extension: { eq: "mdx" }
        }
        sort: { order: DESC, fields: name }
      ) {
        edges {
          node {
            childMdx {
              id
              excerpt(pruneLength: 300)
              fields {
                slug
              }
              frontmatter {
                title
                cover {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      ogImage: file(relativePath: { eq: "og_images/turmgespraeche.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  const { edges: episodes } = data.allFile

  return (
    <Layout backdrop="turmgespraeche">
      <Seo
        title="Turmgespräche"
        description="Lesungen, Podcasts und Interviews setzen sich mit Hölderlin, seinen Werken und der Literatur der Gegenwart auseinander."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Turmgespräche",
              link: "/turmgespraeche",
            },
          ]}
        />
        <PageTitle>Turmgespräche</PageTitle>
        <Paragraph dropcap={true}>
          Lesungen, Podcasts und Interviews setzen sich mit Hölderlin, seinen
          Werken und der Literatur der Gegenwart auseinander.
        </Paragraph>
        <Divider border={4} />
        <Stack space={24}>
          {episodes.map(({ node: episode }, index) => (
            <Columns
              space={12}
              collapse={[true, false]}
              key={`turmgespraeche_${index}_${episode.id}`}
            >
              <Column width={64}>
                <Link to={`/turmgespraeche${episode.childMdx.fields.slug}`}>
                  <Image
                    alt={`Titelbild für ${episode.childMdx.frontmatter.title}`}
                    image={
                      episode.childMdx.frontmatter.cover.childImageSharp
                        .gatsbyImageData
                    }
                  />
                </Link>
              </Column>
              <Column>
                <Box key={episode.childMdx.id}>
                  <Stack>
                    <Stack space={3}>
                      <Link
                        to={`/turmgespraeche${episode.childMdx.fields.slug}`}
                      >
                        <Heading as="h2" level={3}>
                          {episode.childMdx.frontmatter.title}
                        </Heading>
                      </Link>
                      {episode.childMdx.excerpt && (
                        <Paragraph>{episode.childMdx.excerpt}</Paragraph>
                      )}
                    </Stack>
                    <Button
                      to={`/turmgespraeche${episode.childMdx.fields.slug}`}
                    >
                      Jetzt anhören
                    </Button>
                  </Stack>
                </Box>
              </Column>
            </Columns>
          ))}
        </Stack>
      </Stack>
    </Layout>
  )
}

export default TurmgespraecheIndex
